import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';

function PageTitle() {
    const [configData, setConfigData] = useState({});
    const getConfigurationData = () => {
        var configData = {};
        fetch('./config.json') // Relative URL to the JSON file
            .then((response) => response.json())
            .then((data) => setConfigData(data))
            .catch((error) => console.error('Error fetching configuration data:', error));
        return configData;
    }
    useEffect(() => {
        getConfigurationData();
    }, []);

    return (
        <Helmet>
            <title>{configData.CompanyName}</title>
        </Helmet>
    );
}
export default PageTitle;